import logo from './images/logo.svg';
import hm from './images/hm.png';
import sony from './images/sony.png';
import honda from './images/honda.png';
import euroflorist from './images/euroflorist.png';
import goodbyekansas from './images/goodbyekansas.png';
import egmont from './images/egmont.svg';
import axis from './images/axis.png';
import standardsdigital from './images/standardsdigital.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        Agile Remote Project Management & Systems Development<br/>
      </header>
      <footer>
        <div className="row mt-4">
          <div className="col-sm-6 col-lg-10">
            <div className="row">
              <div className="col-sm-6 col-lg-3"><img src={hm} className="clientLogo" alt="logo" /></div>
              <div className="col-sm-6 col-lg-3"><img src={honda} className="clientLogo" alt="logo" /></div>
              <div className="col-sm-6 col-lg-3"><img src={sony} className="clientLogo" alt="logo" /></div>
              <div className="col-sm-6 col-lg-3"><img src={euroflorist} className="clientLogo" alt="logo" /></div>
              <div className="col-sm-6 col-lg-3"><img src={goodbyekansas} className="clientLogo" alt="logo" /></div>
              <div className="col-sm-6 col-lg-3"><img src={egmont} className="clientLogo" alt="logo" /></div>
              <div className="col-sm-6 col-lg-3"><img src={axis} className="clientLogo" alt="logo" /></div>  
              <div className="col-sm-6 col-lg-3"><img src={standardsdigital} className="clientLogo" alt="logo" /></div>        
            </div>            
          </div>
          <div className="col-sm-6 col-lg-2">p@lerudi.com<br/>+34 616 29 00 68<br/><a href="https://www.linkedin.com/in/peterstahl/">LinkedIn</a></div>
        </div>
      </footer>
    </div>
  );
}

export default App;
